import React from 'react'
import { heroCat } from '../assets'

const Hero = () => {
  return <div className="hero-section-container">
    <div className="hero-info-wrapper">
        <div className="hero-info-text">
            <h1 className="title">
                ANGY KITTENS 
            </h1>
            <h2>The meme coin that's  <span className="highlighted">clawing</span> its way through the <span className="solana">Solana</span> blockchain.
            </h2>
            
            <p className='hero-info-description'>
                <i>They're cute, they're cranky, and hellbent on world domination!</i>😾
            </p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>
    </div>
        <div className="hero-image-container">
            <div>
                <img className="hero-image" src={heroCat} alt="Angy Kittens"/>
            </div>
        </div>
  </div>
}

export default Hero