import React from 'react'

const Stats = () => {
  return (

    <div className='stats-container gradient-border'>
        <div className='metric-container'>
            <span className='metric-title'>Total Supply</span>
            <span className='metric-value'>1 Billion 💰</span>
        </div>
        <div className='metric-container'>
            <span className='metric-title'>Liquidity Pool</span>
            <span className='metric-value'>Burned 🔥</span>
        </div>
        <div className='metric-container'>
            <span className='metric-title'>Mint Authority</span>
            <span className='metric-value'>Revoked ✔</span>
        </div>
    </div>
  )
}

export default Stats