import React from 'react'

const Main = () => {
  return <div className="hero-section-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <div className="hero-info-wrapper">
        <div className="hero-info-text" style={{ textAlign: 'center' }}>
            <h2 style={{ fontSize: '1.70rem' }}>Ready for a fur-tastic surprise? For every new member joining our Telegram group, we're airdropping a flurry of $ANGY tokens. So hop in, invite your friends, and let the airdrops rain down like catnip! Keep your eyes peeled on our channels for the next surprise airdrop event – it could happen at any meow-ment. <br></br><br></br><span style={{ fontSize: '2rem' }}>Join the #AngyArmy today!</span></h2>
            <br />
            <br />
        </div>
        <div className="meme-box">
          {/* this div will have a 2x3 grid that will hold images */}
          <div className="meme-grid">
            
          </div>
        </div>
    </div>
  </div>
}


export default Main